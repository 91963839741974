import React from 'react'
import { graphql, Link } from 'gatsby'
import get from 'lodash/get'
import { Helmet } from 'react-helmet'
import Hero from '../components/hero'
import Layout from '../components/layout'
import heroStyles from '../components/hero.module.css'
import Img from 'gatsby-image'

class ProjectsIndex extends React.Component {
  render() {
    const siteTitle = get(this, 'props.data.site.siteMetadata.title')
    const [headerImage] = get(this, 'props.data.allContentfulAsset.edges')

    return (
      <Layout location={this.props.location}  pageName={'projects'}>
        <div style={{ background: '#fff' }}>
          <Helmet title={'Projects | ' + siteTitle} defer={false}/>
          <div className={heroStyles.hero}>
            <Img className={heroStyles.heroImage} alt={headerImage.node.title} fluid={headerImage.node.fluid} />
          </div>
          <div className="wrapper">
            <h1 className="section-headline">Projects</h1>
            <h2 className="section-list">Upcoming</h2>
            <h3>TeamTrends (Public Launch Fall 2020)</h3>
            <ul>
              <li>Help your team stay on track and more easily identify bottlenecks to productivity</li>
            </ul>
            <p>If you're interested in the beta please setup an appointment.</p>
            <a href={"https://calendly.com/warnero/intro"} className={"btn btn-blue"}>I'm Interested!</a>

            <h2 className="section-list">Past</h2>
            <h3>Government Infectious Disease project</h3>
            <ul>
              <li>Delivered multiple Cloud Formation templates for application deployments on ECS and Fargate</li>
              <li><strong>Technologies Used:</strong> Cloud Formation, AWS Cloud Development Kit (CDK), ECS, Fargate, EC2</li>
            </ul>
            <h3>REST API for multiple back-ends</h3>
            <ul>
              <li>Architected and designed a new API to wrap existing services for portability with over 50 different endpoints.</li>
              <li>Implemented new authentication microservice and front-end API gateway utilizing JWT tokens.</li>
              <li><strong>Technologies Used:</strong> Node.js v10, ES2018+ JS language features, MongoDB, mocha testing framework, Docker, Kubernetes</li>
            </ul>
            <h3>Service Contracting System project</h3>
            <ul>
              <li>Implemented new features for a service contracting system (similar to <a href={"https://getjobber.com"} target={"_new"}>Jobber</a>)</li>
              <li>Implemented new REST-based payment module with unit and integration tests</li>
              <li><strong>Technologies Used:</strong> Node.js v6, ES2015 language features, AngularJS 1.x, MongoDB, mocha testing framework, and Heroku for deployments</li>
            </ul>
            <h3>E-commerce Favorites project</h3>
            <ul>
              <li>Migrated existing Favorites to new back-end, leveraging AWS services and hosting</li>
              <li><strong>Technologies Used:</strong> Java, Spring, Internal AWS frameworks</li>
            </ul>
            <h3>Mobile TV commentary app</h3>
            <ul>
              <li>Launched a mobile TV commentary app using Node.js and CoffeeScript</li>
              <li>Implemented ElasticSearch search solution and Redis caching service to improve search times for users</li>
              <li><strong>Technologies Used:</strong> CoffeeScript, Node.js, JavaScript, MongoDB, Redis, ElasticSearch</li>
            </ul>
            <h3>AdTech web application</h3>
            <ul>
              <li>Rewrote the initial PHP MVP into a more scalable and reliable application using Node.js, jQuery and MongoDB</li>
              <li>Rewrote application to pivot to a new market segment, making the application more dynamic and user-friendly by leveraging AngularJS 1.x, Redis and ElasticSearch</li>
              <li>Wrote a brand-new service that allows users to retarget their customers easily using relevant third-party content. (Patent pending)</li>
              <li>Developed streaming prototype using AWS Kinesis and Node.js</li>
              <li><strong>Technologies Used:</strong> AngularJS, Node.js, jQuery, MongoDB, Redis, ElasticSearch, Kinesis</li>
            </ul>
            <h3>Event discovery mobile and web application</h3>
            <ul>
              <li>Developed REST-based API to sync events from multiple sources into one common format</li>
              <li><strong>Technologies Used:</strong> Java, Spring, Ruby, jRuby, jQuery, CouchDB, MySql, Redis, ElasticSearch</li>
            </ul>
            <a href={"https://calendly.com/warnero/intro"} className={"btn btn-blue"}>Let's Work Together!</a>

          </div>
        </div>
      </Layout>
    )
  }
}

export default ProjectsIndex

export const pageQuery = graphql`
  query ProjectsQuery {
    site {
      siteMetadata {
        title
      }
    }
    allContentfulAsset(filter: {contentful_id: {eq: "1K3SFExHkljzt9dB3Rm97U"}}) {
      edges {
        node {
          title
          fluid(maxWidth: 1180, background: "rgb:000000") {
            ...GatsbyContentfulFluid_tracedSVG
          }
        }
      }
    }
  }
`
